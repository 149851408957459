import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Text,
	Button,
	VStack,
	Divider
} from "@chakra-ui/react"
import React, { useState, createContext, FC, useMemo, useCallback } from "react"
import { colors } from "@styles/colors"
import { sleep } from "@helpers/sleep"
import { ActionSheetMenu } from "@type/common/ActionSheet"

type ActionSheetOptions = {
	options: ActionSheetMenu[]
}

type ActionSheetContextType = {
	showActionSheetWithOptions: (options: ActionSheetMenu[]) => void
}

const initialValues = {
	showActionSheetWithOptions: () => {}
}

export const ActionSheetContext =
	createContext<ActionSheetContextType>(initialValues)

type Props = {
	children: React.ReactNode
}
export const ActionSheetContextProvider: FC<Props> = ({ children }) => {
	const [isShown, setIsShown] = useState(false)
	const [menuList, setMenuList] = useState<ActionSheetMenu[]>([])
	const show = useCallback(() => setIsShown(true), [])
	const dismiss = useCallback(() => setIsShown(false), [])

	const showActionSheetWithOptions = async (options: ActionSheetMenu[]) => {
		setMenuList(options)
		// await sleep(100)
		show()
	}

	const ActionSheet = useMemo(
		() => (
			<Modal isOpen={isShown} onClose={dismiss} isCentered>
				<ModalOverlay />
				<ModalContent
					backgroundColor={"rgba(255,255,255,1)"}
					backdropFilter="auto"
					backdropBlur="10px"
					rounded="2xl"
					overflow={"hidden"}
					userSelect="none"
					px={1}
				>
					<VStack divider={<Divider />} spacing={0}>
						{menuList.map((menu) => (
							<Button
								w="100%"
								py={8}
								onClick={() => {
									menu.func()
									dismiss()
								}}
								bg="rgba(0,0,0,0)"
								color="text.700"
								_hover={{ bg: colors.ui.primary[200] }}
								rounded="none"
								key={menu.label}
							>
								<Text>{menu.label}</Text>
							</Button>
						))}
						<Button
							w="100%"
							py={8}
							onClick={dismiss}
							bg="rgba(0,0,0,0)"
							color="text.700"
							_hover={{ bg: colors.ui.primary[200] }}
							rounded="none"
							key={"cancel"}
						>
							<Text>キャンセル</Text>
						</Button>
					</VStack>
				</ModalContent>
			</Modal>
		),
		[menuList, isShown]
	)

	return (
		<ActionSheetContext.Provider value={{ showActionSheetWithOptions }}>
			{children}
			{ActionSheet}
		</ActionSheetContext.Provider>
	)
}
