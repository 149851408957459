import { Flex, PinInput, PinInputField } from "@chakra-ui/react"
import { range } from "@helpers/range"
import { useCCBreakpointValue } from "@hooks/useCCBreakpointValue"
import { useCCWindowDimensions } from "@hooks/useCCWindowDimensions"
import { colors } from "@styles/colors"
import { FC } from "react"

type Props = {
	inputNum: number
	eachInputTextSize: number
	value: string
	setValue: (v: string) => void
}

export const DigitsInputs: FC<Props> = ({
	inputNum,
	eachInputTextSize,
	value,
	setValue,
}) => {
	const { width: screenWidth } = useCCWindowDimensions()
	const size = useCCBreakpointValue({ base: "sm", md: "md", lg: "lg" })
	return (
		<Flex alignItems="center" overflowX={"scroll"} maxW="calc( 100vw - 32px )">
			<PinInput
				type="number"
				value={value}
				onChange={(value) => setValue(value)}
				placeholder="0"
				size={size}
				colorScheme={"primary"}
			>
				{range(0, inputNum * eachInputTextSize).map((index) => {
					const isLastInput = index === inputNum * eachInputTextSize - 1
					const hasHiphen =
						index % eachInputTextSize === eachInputTextSize - 1 && !isLastInput
					return (
						<PinInputField
							key={index}
							autoFocus={index === 0}
							mr={hasHiphen ? (640 < screenWidth ? 10 : 4) : 0}
							position="relative"
							bg="#fff"
							_after={{
								width: 12,
								h: 1,
								bg: colors.text.black[300],
								content: "''",
							}}
						/>
					)
				})}
			</PinInput>
		</Flex>
	)
}
