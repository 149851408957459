import {
	formatDistanceToNow,
	isAfter,
	isBefore,
	isSameDay,
	subMonths
} from "date-fns"
import format from "date-fns/format"
import ja from "date-fns/locale/ja"

export const formatDate2YYYYMMDD = (date: Date) => {
	return format(date, "yyyy-MM-dd")
}

export const formatDate2YYYYMDWithWeekDay = (date: Date) => {
	return format(date, "yyyy年 M/d(E)", { locale: ja })
}

export const formatDate2YYYYMDHmmWithWeekDay = (date: Date) => {
	return format(date, "yyyy-M-d (E) H:mm", { locale: ja })
}

export const formatDate2YYYYMDHmmWithWeekDayInJp = (date: Date) => {
	return format(date, "yyyy年M月d日 (E) H:mm", { locale: ja })
}

export const formatDate2MMdd = (date: Date) => {
	return format(date, "MM/dd", { locale: ja })
}
export const formatDate2Md = (date: Date) => {
	return format(date, "M/d", { locale: ja })
}

export const formatDate2HHmm = (date: Date) => {
	return format(date, "HH:mm", { locale: ja })
}

export const formatDate2Hm = (date: Date) => {
	return format(date, "H:mm", { locale: ja })
}

export const isSameDayAndBefore = (day: Date, baseDay: Date) => {
	return isSameDay(day, baseDay) && isBefore(day, baseDay)
}

export const isSameDayAndAfter = (day: Date, baseDay: Date) => {
	return isSameDay(day, baseDay) && isAfter(day, baseDay)
}

export const distanceToNow = (date: Date) => {
	return formatDistanceToNow(date, { addSuffix: true, locale: ja })
}

export const getNendo = (date: Date) => {
	return subMonths(date, 3).getFullYear()
}
