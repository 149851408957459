import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { CourseRead, CourseSimpleRead } from "@type/models/Course"

export type CourseType = {
	currentCourse: CourseRead | CourseSimpleRead | undefined
}

const initialState: CourseType = {
	currentCourse: undefined,
}

const slice = createSlice({
	name: "course",
	initialState,
	reducers: {
		setCurrentCourse: (
			state: CourseType,
			action: PayloadAction<CourseSimpleRead>
		) => {
			return { ...state, currentCourse: action.payload }
		},
	},
})

export const courseReducer = slice.reducer
export const { setCurrentCourse } = slice.actions
