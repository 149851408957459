import { StudentsApi } from "@apis/typescript-axios"
import { Button, Flex, Input, ModalBody, VStack } from "@chakra-ui/react"
import { CCVStack } from "@components/CCVStack"
import { TextWithFurigana } from "@components/TextWithFurigana"
import { useShouldJoinNewClassroom } from "@features/affiliationChange/student/useShouldJoinNewClassroom"
import { zenkaku2Hankaku } from "@helpers/zenkaku2hankaku"
import { useCCToast } from "@hooks/useCCToast"
import { useDigitsInputs } from "@hooks/useDigitsInputs"
import { useErrorToast } from "@hooks/useErrorToast"
import { useModal } from "@hooks/useModal"
import { ApiParams, useServerErrors } from "@hooks/useServerErrors"
import { useCallback, useMemo, useState } from "react"

const getApi = () => new StudentsApi()

type Params = {
	onSuccess?: () => void
}

export const useNewClassroomJoinModal = ({ onSuccess }: Params) => {
	const { addToast } = useCCToast()
	const { addErrorToast } = useErrorToast()
	const { refetch } = useShouldJoinNewClassroom()
	const [isJoining, setIsJoining] = useState(false)
	const { errorComponentDict, updateErrorComponentDict } = useServerErrors<
		ApiParams<StudentsApi, "changeClassroom", 0> | "nonFieldErrors"
	>()

	const { DigitsInputs, inputText: digits } = useDigitsInputs({
		inputNum: 3,
		eachInputTextSize: 4
	})

	const [numInClass, setNumInClass] = useState<string>()

	const { ModalWrapper, closeModal, openModal } = useModal({
		modalProps: { scrollBehavior: "inside", size: "xl" }
	})

	const isValid = digits.length === 12 && !!numInClass

	const joinClassroom = useCallback(async () => {
		if (!numInClass || isJoining) return
		const pattern = /^([1-9]\d*)|""$/
		const isValid = pattern.test(numInClass)
		if (!isValid) {
			addToast({
				text: "半角(はんかく)の数字(すうじ)を入力してください",
				type: "info"
			})
			return
		}
		setIsJoining(true)
		try {
			const { data } = await getApi().changeClassroom({
				joinCode: digits,
				numInClass: Number(numInClass)
			})
			addToast({ text: `${data.label}へ参加しました`, type: "success" })
			onSuccess?.()
			refetch()
			closeModal()
		} catch (e) {
			console.warn(e)
			updateErrorComponentDict(e)
			addErrorToast({
				error: e,
				addServerErrorMessages: true,
				baseText: "クラスの参加に失敗しました"
			})
		} finally {
			setIsJoining(false)
		}
	}, [
		numInClass,
		isValid,
		digits,
		isJoining,
		setIsJoining,
		onSuccess,
		refetch,
		closeModal,
		updateErrorComponentDict
	])

	const Content = useMemo(
		() => (
			<ModalBody bg="#fff">
				<CCVStack
					maxW="720px"
					w="100%"
					mx={2}
					justify="center"
					bg="#ffffff"
					rounded="md"
					p={8}
					spacing={8}
				>
					<VStack w="100%" spacing={1} alignSelf="flex-start">
						<TextWithFurigana
							stackProps={{ alignSelf: "flex-start" }}
							furigana="あたらしいくらすのさんかこーど"
							align="left"
						>
							新しいクラスの参加コード
						</TextWithFurigana>
						{DigitsInputs}
					</VStack>
					<VStack w="100%" spacing={1} alignSelf="flex-start">
						<TextWithFurigana
							stackProps={{ alignSelf: "flex-start" }}
							furigana="あたらしいくらすのさんかこーど"
							align="left"
						>
							新しいクラスの出席番号
						</TextWithFurigana>
						<Input
							value={numInClass}
							w={20}
							alignSelf="flex-start"
							type="number"
							min={1}
							onChange={(e) =>
								setNumInClass(zenkaku2Hankaku(e.target.value || ""))
							}
						/>
						{errorComponentDict.numInClass}
					</VStack>
					{errorComponentDict.nonFieldErrors}
					<Flex
						w="100%"
						flexDir={{ base: "column-reverse", md: "row" }}
						alignItems="flex-end"
						justifyContent="flex-end"
						alignSelf={"flex-end"}
						mt={8}
					>
						<Button
							onClick={closeModal}
							variant="cancel"
							mt={{ base: 6, md: 0 }}
							minW="120px"
						>
							<TextWithFurigana furigana={"もどる"}>戻る</TextWithFurigana>
						</Button>

						<Button
							onClick={joinClassroom}
							isDisabled={!isValid}
							variant={"solid"}
							ml={{ md: 8 }}
							minW="120px"
							isLoading={isJoining}
						>
							<TextWithFurigana
								color="#fff"
								furigana={"おーけー"}
								align="center"
							>
								OK
							</TextWithFurigana>
						</Button>
					</Flex>
				</CCVStack>
			</ModalBody>
		),
		[
			DigitsInputs,
			setNumInClass,
			isJoining,
			numInClass,
			errorComponentDict,
			setNumInClass,
			closeModal,
			joinClassroom
		]
	)

	const Modal = useMemo(() => <ModalWrapper>{Content}</ModalWrapper>, [Content])

	return {
		Modal,
		openModal
	}
}
