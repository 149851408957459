import { setCurrentCourse as setCurrentCourseByStore } from "@stores/course"
import { RootState } from "@stores/index"
import { CourseSimpleRead } from "@type/models/Course"
import { useCallback, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import useSWR from "swr"

type ReturnType = {
	courses: CourseSimpleRead[]
	currentCourse: CourseSimpleRead | undefined
	setCurrentCourse: (c: CourseSimpleRead) => void
	refetch: () => Promise<void>
	isRefreshing: boolean
}

const GET_USERS_COURSES_KEY = `/api/v1/users/me/courses`

export const useCourses = (): ReturnType => {
	const { data: _courses, mutate } = useSWR<CourseSimpleRead[]>(
		GET_USERS_COURSES_KEY
	)
	const [isRefreshing, setIsRefreshing] = useState(false)
	const refetch = useCallback(async () => {
		setIsRefreshing(true)
		await mutate()
		setIsRefreshing(false)
	}, [mutate])

	const dispatch = useDispatch()

	const { currentCourse } = useSelector((state: RootState) => state.course)
	const setCurrentCourse = useCallback(
		(course: CourseSimpleRead) => dispatch(setCurrentCourseByStore(course)),
		[]
	)

	// 指定がなければ一番最初のcourseをcurrentCourseにする
	// useEffect(() => {
	// 	!currentCourse && _courses && setCurrentCourse(_courses[0])
	// }, [_courses, currentCourse])

	const courses = useMemo(() => _courses || [], [_courses])

	// const currentCourse_ = useMemo(() => {
	// 	return {
	// 		subjectObject: {
	// 			label: "",
	// 			value: ""
	// 		},
	// 		...currentCourse
	// 	}
	// }, [currentCourse])

	return {
		courses,
		currentCourse,
		setCurrentCourse,
		refetch,
		isRefreshing
	}
}
