import { GoogleAuthProvider, OAuthProvider } from "firebase/auth"

export const GOOGLE_PROVIDER_META = {
	providerFactory: () => new GoogleAuthProvider(),
	label: "Goolge"
} as const
export const MICROSOFT_PROVIDER_META = {
	providerFactory: () => {
		const provider = new OAuthProvider("microsoft.com")
		provider.setCustomParameters({
			// redirect_uri: "http://127.0.0.1:3010",
			// prompt: "login",
			tenant: "common"
			// redirect_uri: "https://localclasscloud.firebaseapp.com/__/auth/handler"
		})
		return provider
	},
	label: "Microsoft"
} as const

export type ProviderMeta =
	| typeof GOOGLE_PROVIDER_META
	| typeof MICROSOFT_PROVIDER_META
