import { Modal as ChakraModal, ModalProps } from "@chakra-ui/react"
import React, { FC } from "react"
type Props = {
	isOpen: boolean
	closeModal: () => void
} & Partial<ModalProps>

export const Modal: FC<Props> = ({
	children,
	isOpen,
	closeModal,
	...ModalProps
}) => {
	return (
		<ChakraModal onClose={closeModal} isOpen={isOpen} {...ModalProps}>
			{children}
		</ChakraModal>
	)
}
