import { isTouchScreen } from "@helpers/detectDevice"
import {
	FirebaseConfig,
	LOCAL_FIREBASE_CONFIG,
	PROD_FIREBASE_CONFIG,
	STG_FIREBASE_CONFIG,
} from "./env_firebase_config"

type EnvironmentType = "prod" | "stg" | "local"
export const ENVIRONMENT = process.env
	.NEXT_PUBLIC_ENVIRONMENT as EnvironmentType

type Config = {
	API_HOST: string
	EDGE_API_WS_HOST: string
	WEB_APP_HOST: string
	WS_HOST: string
	LP_HOST: string
	STATIC_FILE_HOST: string
	AMPLITUDE_SDK_ID: string | undefined
	FIREBASE_CONFIG: FirebaseConfig
	ENVIRONMENT: EnvironmentType
	MICROCMS_API_KEY: string
}

const PROD_CONFIG: Config = {
	API_HOST: "https://api.class-cloud.jp",
	EDGE_API_WS_HOST: "wss://edge-api.class-cloud.jp",
	WEB_APP_HOST: "https://app.class-cloud.jp",
	WS_HOST: "wss://ws.class-cloud.jp",
	LP_HOST: "https://class-cloud.jp",
	STATIC_FILE_HOST: "https://static.class-cloud.jp/static",
	AMPLITUDE_SDK_ID: "596047b58d7ebdddd2e3b48673f96716",
	FIREBASE_CONFIG: PROD_FIREBASE_CONFIG,
	MICROCMS_API_KEY: "enStLz9Sbq1NoF9WVu1HF6PvlcUctfKRVjfC",
	ENVIRONMENT: "prod",
}

const STG_CONFIG: Config = {
	API_HOST: "https://stg-m-api.class-cloud.jp",
	EDGE_API_WS_HOST: "wss://stg-m-edge-api.class-cloud.jp",
	WEB_APP_HOST: "https://stg-m-app.class-cloud.jp",
	WS_HOST: "wss://stg-m-ws.class-cloud.jp",
	LP_HOST: "https://stg.class-cloud.jp",
	STATIC_FILE_HOST: "https://stg-static.class-cloud.jp/static",
	AMPLITUDE_SDK_ID: undefined,
	FIREBASE_CONFIG: STG_FIREBASE_CONFIG,
	MICROCMS_API_KEY: "enStLz9Sbq1NoF9WVu1HF6PvlcUctfKRVjfC",
	ENVIRONMENT: "stg",
}

const LOCAL_IP = "192.168.2.100"

const LOCAL_CONFIG: Config = {
	// API_HOST: "https://stg-m-api.class-cloud.jp",
	// API_HOST: "http://192.168.2.102:8099",
	API_HOST: isTouchScreen()
		? `http://${LOCAL_IP}:8099`
		: "http://127.0.0.1:8099",
	// API_HOST: "http://127.0.0.1:8099",
	EDGE_API_WS_HOST: "wss://stg-m-edge-api.class-cloud.jp",
	// API_HOST: "http://192.168.2.102:8089",
	// API_HOST: "http://192.168.2.113:8099",
	// WS_HOST: "ws://127.0.0.1:8097",
	WS_HOST: `ws://${LOCAL_IP}:8097`,
	// WEB_APP_HOST: "http://127.0.0.1:3010",
	WEB_APP_HOST: `http://${LOCAL_IP}:3010`,
	// API_HOST: "http://${LOCAL_IP}:8099",
	STATIC_FILE_HOST: "http://127.0.0.1:9000/class-cloud/static",
	LP_HOST: "https://stg.class-cloud.jp",
	AMPLITUDE_SDK_ID: undefined,
	FIREBASE_CONFIG: LOCAL_FIREBASE_CONFIG,
	MICROCMS_API_KEY: "enStLz9Sbq1NoF9WVu1HF6PvlcUctfKRVjfC",
	ENVIRONMENT: "local",
}

export const ENV_CONFIG: Config =
	ENVIRONMENT === "prod"
		? PROD_CONFIG
		: ENVIRONMENT === "stg"
		? STG_CONFIG
		: LOCAL_CONFIG

export const isNotProd =
	ENV_CONFIG.ENVIRONMENT === "stg" || ENV_CONFIG.ENVIRONMENT === "local"
