import { useConst } from "@chakra-ui/react"
import { CCLocalStorage } from "@utils/CCLocalStorage"

export const useIsTestMode = () => {
	const isTestMode = useConst(() => {
		const isTestModeFromStorage = CCLocalStorage.getItem("isTestMode", false)
		return isTestModeFromStorage
	})

	return {
		isTestMode
	}
}
