import { getNendo } from "@helpers/date"
import { range } from "@helpers/range"

const START_YEAR = 2022

export const nendoList = range(START_YEAR, new Date().getFullYear() + 1)

export const thisNendo = getNendo(new Date())
export const nearNendoForCreate = [0, 1].includes(new Date().getMonth())
	? nendoList.slice().reverse()[1]!
	: nendoList.slice().reverse()[0]!

export const getNendoStartDay = (date: Date) => {
	const year = date.getFullYear()
	const nendoStartDay = new Date(`${year}-04-01`)
	if (date < nendoStartDay) {
		nendoStartDay.setFullYear(year - 1)
	}
	return nendoStartDay
}

export const isNewNendoDays = (date: Date) => {
	const month = date.getMonth() + 1
	if ([4, 5].includes(month)) {
		return true
	}
	return false
}
