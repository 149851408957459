import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export type QueryStringState = {
	[key in string]: string
}

const initialState: QueryStringState = {}

const slice = createSlice({
	name: "queryString",
	initialState,
	reducers: {
		add: (
			state: QueryStringState,
			action: PayloadAction<{ key: string; value: string }>
		) => {
			const newState = { ...state }
			newState[action.payload.key] = action.payload.value
			return newState
		},
		remove: (
			state: QueryStringState,
			action: PayloadAction<{ key: string }>
		) => {
			const newState = { ...state }
			delete newState[action.payload.key]
			return newState
		}
	}
})

export const queryStringReducer = slice.reducer
export const { add, remove } = slice.actions
