import { ColorMap } from "@features/canvas/color/RecentColorContext"
import { BgProperty, CanvasElement } from "@features/canvas/elementType"
import { DraftPost } from "@features/draftPost"
import { Slide } from "@type/models/Canvas"
import { ClassroomSimpleRead } from "@type/models/Classroom"
import { CourseRead, Subject } from "@type/models/Course"
export type LocalStorageKeyValues = {
	shouldReloadAppWithoutInitialNavigation: boolean
	expoPushToken: string
	shouldOptOutAnalytics: boolean
	chatMessageInput: string
	recentlyUsedColors: string[]
	recentlyUsedColorMap: ColorMap
	pendingCanvas: {
		elementList: CanvasElement[]
		stagePixelSize: { width: number; height: number }
		bgProperty: BgProperty | undefined
	}
	pendingCanvas_v1: {
		slideList: Slide[]
		stagePixelSize: { width: number; height: number }
	}
	newsViewLastDatetime: string
	newsViewLastDatetime2: string
	hideSchoolChangeButtonPressedDatetime: string // for teacher
	readNewsIds: string[]
	selectedClassroomIdInHomePageCourseSelect: string | undefined
	openedClassroomSubjectValuesInHomePageCourseSelect: {
		[key in ClassroomSimpleRead["id"]]: Subject["value"][]
	}
	selectedGradeInHomePageCourseSelect: string | undefined
	openedGradeSubjectValuesInHomePageCourseSelect: {
		[key in string]: Subject["value"][]
	}
	courseSelectSideBarType: "perGrade" | "flat" | undefined
	isTestMode: boolean
	courseFukaboriCountMap: { [key in string]: number }
	courseDraftPostMap: { [key in CourseRead["id"]]: DraftPost }
}

type Keys = keyof LocalStorageKeyValues

export const CCLocalStorage = {
	setItem: <T extends Keys>(key: T, value: LocalStorageKeyValues[T]): void =>
		localStorage.setItem(key, JSON.stringify(value)),

	getItem: <T extends Keys, S = undefined>(
		key: T,
		defaultValue: S,
	): LocalStorageKeyValues[T] | S => {
		const item = localStorage.getItem(key)
		if (item === null) {
			return defaultValue
		}
		if (item === "undefined") {
			return defaultValue
		}
		try {
			const v = JSON.parse(item)
			return v
		} catch (e) {
			console.error(e)
			return defaultValue
		}
	},

	removeItem: <T extends Keys>(key: T): void => localStorage.removeItem(key),

	clear: (): void => localStorage.clear(),
}
