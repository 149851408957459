import { useCourses } from "@hooks/useCourses"
import { useOwnClassroomPropertiesString } from "@hooks/useOwnClassroomsPropertiesString"
import { useUser } from "@hooks/useUser"
import * as Sentry from "@sentry/nextjs"
import { AnalyticsLog } from "@utils/analytics/amplitude"
import { useEffect } from "react"

export const useSetUserPropertiesToLogger = () => {
	const user = useUser()
	const { currentCourse } = useCourses()
	const {
		data: classroomsPropertiesString,
		mutate: refreshOwnClassroomProperties
	} = useOwnClassroomPropertiesString({ isLoggedIn: !!user?.roleId })

	useEffect(() => {
		user && refreshOwnClassroomProperties()
	}, [user])

	useEffect(() => {
		if (!user) return
		const userInfo = {
			id: user.id,
			roleId: user.roleId,
			roleType: user.roleType,
			fullName: user.fullName,
			currentCourse,
			classroomsPropertiesString: classroomsPropertiesString?.propertiesString
		}
		AnalyticsLog.setUserPropertiesToLogger(userInfo)
		Sentry.setUser(userInfo)
	}, [user, currentCourse, classroomsPropertiesString?.propertiesString])
}
