import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { CourseSimpleRead } from "@type/models/Course"

export type NotificationType = {
	currentCourse: CourseSimpleRead | undefined
}
const initialState: NotificationType = {
	currentCourse: undefined,
}

const slice = createSlice({
	name: "notification",
	initialState,
	reducers: {
		setCurrentCourse: (
			state: NotificationType,
			action: PayloadAction<CourseSimpleRead>
		) => {
			return { ...state, currentCourse: action.payload }
		},
	},
})

export const notificationReducer = slice.reducer
export const { setCurrentCourse: setNotificationCurrentCourse } = slice.actions
