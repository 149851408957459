import { TextProps } from "@chakra-ui/react"
import React, { FC } from "react"
import { TextHiddenWhenEmpty } from "@components/TextHiddenWhenEmpty"

type Props = TextProps

export const ErrorMessage: FC<Props> = ({ children, ...restProps }) => {
	if (Array.isArray(children)) {
		return (
			<>
				{children.map((t) => (
					<TextHiddenWhenEmpty
						w="100%"
						color="system.error"
						mt={2}
						lineHeight={"20px"}
						key={t?.toString()}
						{...restProps}
					>
						{t}
					</TextHiddenWhenEmpty>
				))}
			</>
		)
	} else {
		return (
			<TextHiddenWhenEmpty
				w="100%"
				color="system.error"
				mt={2}
				lineHeight={"20px"}
				{...restProps}
			>
				{children}
			</TextHiddenWhenEmpty>
		)
	}
}
