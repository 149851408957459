//startからstopを含まない数までの連続した整数を返す
// stopの方が小さい数の場合は range(5,1, -1) -> [5,4,3,2]
// range(5,5) -> []
// range(5,4,-1) -> [5]
// stepが0のときはエラー
/**
 *
 * @param start
 * @param stop return array does not include this value
 * @param step
 * @returns
 */
export const range = (start: number, stop: number, step = 1) => {
	if (step === 0) throw new Error("Error: step must not be zero")
	if ((stop - start) * step < 0)
		throw new Error(
			"Error: step must be negative when start is greater than stop or be positive when start is smaller than stop"
		)
	let num = start
	const result: number[] = []
	while (start < stop ? num < stop : stop < num) {
		result.push(num)
		num += step
	}

	return result
}
