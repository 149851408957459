export interface IColorHues {
	50: string
	100: string
	200: string
	300: string
	400: string
	500: string
	600: string
	700: string
	800: string
	900: string
}

const colors = {
	contrastThreshold: 7,
	ui: {
		primary: {
			50: "#f5fcff",
			100: "#EBF8FE",
			200: "#EBF8FE",
			300: "#C3EBFD",
			400: "#82d6fb",
			500: "#38BDF8",
			600: "#38BDF8",
			700: "#38A3F8",
			800: "#2c82c6",
			900: "#216194",
		},
		accent: {
			50: "#feeeeb",
			100: "#fdddd7",
			200: "#fdddd7",
			300: "#fa9b87",
			400: "#f97a5f",
			500: "#F85938",
			600: "#F85938",
			700: "#f7441f",
			800: "#F83007",
			900: "#F83007",
		},
		green: {
			50: "#e4fee8",
			100: "#befcc6",
			200: "#8bfaa0",
			300: "#38f872",
			400: "#00f348",
			500: "#00eb24",
			600: "#00da12",
			700: "#00c500",
			800: "#00b300",
			900: "#009100",
		},
		gray: {
			300: "#E5E5E5",
			400: "#CCC",
			500: "#898989",
			700: "#657786",
		},
		destructive: {
			50: "#FFF2F2",
			100: "#FFC4C4",
			200: "#FCAFB6",
			300: "#FE9696",
			400: "#FC676D",
			500: "#F8384A",
			600: "#F8384A",
			700: "#C31632",
			800: "#C31632",
			900: "#C31632",
		},
	},
	system: {
		error: "#F8384A",
		warning: "#F87338",
		info: "#38b0f8",
		success: "#4ade80",
		disabled: "#EAEAEA",
	},
	bg: {
		black: {
			100: "#f9f9f9",
			200: "#efefef",
			300: "#E5E5E5",
			500: "rgba(0,0,0,0.7)",
			700: "rgba(0,0,0,0.87)",
		},
		gray: {
			50: "#F7FAFC",
			100: "#EDF2F7",
			200: "#E2E8F0",
			300: "#CBD5E0",
			400: "#A0AEC0",
			500: "#718096",
			600: "#4A5568",
			700: "#2D3748",
			800: "#1A202C",
			900: "#171923",
		},
	},
	text: {
		black: {
			300: "#A1A8AB",
			350: "#8499a6",
			400: "#717B80",
			500: "#343C3F",
			700: "#1F3037",
			900: "#000",
		},
	},
	border: {
		primary: "#38BDF8",
		black: "#A1A8AB",
		gray: "#E5E5E5",
	},
	white: "#fff",
	black: "#000",
	blackAlpha: {
		50: "rgba(0, 0, 0, 0.04)",
		100: "rgba(0, 0, 0, 0.06)",
		200: "rgba(0, 0, 0, 0.08)",
		300: "rgba(0, 0, 0, 0.16)",
		400: "rgba(0, 0, 0, 0.24)",
		500: "rgba(0, 0, 0, 0.36)",
		600: "rgba(0, 0, 0, 0.48)",
		700: "rgba(0, 0, 0, 0.64)",
		800: "rgba(0, 0, 0, 0.80)",
		900: "rgba(0, 0, 0, 0.92)",
	},
	blackAlphaWithoutAlpha: {
		50: "#f4f4f4",
		100: "#efefef",
		200: "#eaeaea",
	},
	whiteAlpha: {
		50: "rgba(255, 255, 255, 0.04)",
		100: "rgba(255, 255, 255, 0.06)",
		200: "rgba(255, 255, 255, 0.08)",
		300: "rgba(255, 255, 255, 0.16)",
		400: "rgba(255, 255, 255, 0.24)",
		500: "rgba(255, 255, 255, 0.36)",
		600: "rgba(255, 255, 255, 0.48)",
		700: "rgba(255, 255, 255, 0.64)",
		800: "rgba(255, 255, 255, 0.80)",
		900: "rgba(255, 255, 255, 0.92)",
	},
}

export { colors }
export type IColors = typeof colors
