import { useBreakpointValue } from "@chakra-ui/react"
import { Breakpoint } from "@styles/breakpoints"

type Params<T> = Partial<{
	[key in Breakpoint]: T
}> & { base: T }

export const useCCBreakpointValue = <T>(params: Params<T>): T => {
	// const defaultValue = params["base"]
	// const currentBreakPoint = useBreakpoint() as string
	const value = useBreakpointValue(params, { ssr: false }) as T
	// const hasValue = currentBreakPoint in params

	return value
}
