import React, { useCallback, useState } from "react"
import { DigitsInputs } from "@components/DigitsInputs"

type Params = {
	inputNum: number
	eachInputTextSize: number
}

export const useDigitsInputs = ({ inputNum, eachInputTextSize }: Params) => {
	const [value, setValue] = useState("")

	const resetText = useCallback(() => setValue(""), [])

	return {
		DigitsInputs: (
			<DigitsInputs
				inputNum={inputNum}
				eachInputTextSize={eachInputTextSize}
				value={value}
				setValue={setValue}
			/>
		),
		inputText: value,
		resetText
	}
}
