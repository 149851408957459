import { ErrorMessage } from "@components/ErrorMessage"
import {
	isHandleableServerError,
	ServerErrorResponse,
	ServerErrors
} from "@type/models/Error"
import React, { useMemo, useState } from "react"

type ErrorMessageComponentDict<T extends string> = {
	[key in T]: React.ReactNode
}

export type ApiParams<
	T extends { [key in string]: any },
	K extends keyof T,
	N extends number = 0
> = keyof NonNullable<Parameters<T[K]>[N]>

// type Params<T extends string> = {
// 	[key in T]: any
// }

// export function useServerErrors<T extends string>(depsObject: Params<T>) {
export function useServerErrors<T extends string>() {
	const [serverErrors, setServerErrors] = useState<ServerErrors<T>>(
		{} as ServerErrors<T>
	)

	// useEffect(() => {
	// 	const updatedServerErrors = {...serverErrors, }
	// }, [...Object.values(depsObject)])

	const resetErrors = () => {
		setServerErrors({} as ServerErrors<T>)
	}

	const updateErrorComponentDict = (error: ServerErrorResponse<T> | any) => {
		isHandleableServerError<T>(error)
			? setServerErrors(error.response.data as ServerErrors<T>)
			: setServerErrors({} as ServerErrors<T>)
		isHandleableServerError<T>(error) && console.warn(error.response.data)
	}

	const updateNonFieldErrors = (nonFieldErrors: string | string[]) => {
		setServerErrors((errors) => ({ ...errors, nonFieldErrors }))
	}

	const ErrorMessageComponentDict = useMemo(() => {
		const baseErrorDict = {} as ErrorMessageComponentDict<T>
		for (const key in serverErrors) {
			baseErrorDict[key as T] = (
				<ErrorMessage>{serverErrors[key as T]}</ErrorMessage>
			)
		}
		return baseErrorDict
	}, [serverErrors])

	return {
		updateErrorComponentDict,
		updateNonFieldErrors,
		resetErrors,
		errorComponentDict: ErrorMessageComponentDict
	}
}
