import { useEffect, useRef } from "react"

import { extractQueryStringValueFromCurrentUrl } from "@helpers/extractQueryStringValue"
import { useCourses } from "@hooks/useCourses"
import { useQueryStrings } from "@hooks/useQueryStrings"

export const useSyncCurrentCourseWithQueryString = () => {
	const { setCurrentCourse, currentCourse, courses } = useCourses()

	// url
	const { addUrlQueryParams, removeUrlQueryParams } = useQueryStrings()

	// url => currentCourse, remove
	const currentCourseIsChangedByUrlRef = useRef(false)
	useEffect(() => {
		loadCourseFromUrl()
	}, [courses])

	const loadCourseFromUrl = async () => {
		if (currentCourseIsChangedByUrlRef.current) return
		if (!courses.length) return

		const courseIdFromUrl = extractQueryStringValueFromCurrentUrl("course_id")
		if (!courseIdFromUrl) {
			currentCourseIsChangedByUrlRef.current = true
			return
		}
		const courseFromUrl = courses.find((c) => c.id === courseIdFromUrl) // 自分の授業だけなので、SuperUser権限で見られる授業にはとべない

		courseFromUrl && setCurrentCourse(courseFromUrl)

		await removeUrlQueryParams({ key: "course_id" })

		currentCourseIsChangedByUrlRef.current = true
	}

	// currentCourse => url
	useEffect(() => {
		addCurrentCourseId()
	}, [currentCourse])

	const addCurrentCourseId = async () => {
		if (!currentCourse) return
		if (!currentCourseIsChangedByUrlRef.current) return

		await addUrlQueryParams({
			key: "course_id",
			value: currentCourse.id
		})
	}
}
