import { useNewClassroomJoinModal } from "@features/affiliationChange/student/useNewClassroomJoinModal"
import { useShouldJoinNewClassroom } from "@features/affiliationChange/student/useShouldJoinNewClassroom"
import { useEffect } from "react"

export const useAutoShowNewClassroomJoinModal = () => {
	const { shouldJoinNewClassroom } = useShouldJoinNewClassroom()
	const { openModal, Modal } = useNewClassroomJoinModal({})

	useEffect(() => {
		if (!shouldJoinNewClassroom) return
		openModal()
	}, [shouldJoinNewClassroom])

	return {
		Modal
	}
}
