// // import { useAppState } from "@react-native-community/hooks"
// // import { useEffect, useRef, useState } from "react"
// // import { useWindowDimensions } from "react-native"
// // import { Dimensions } from "react-native"
// import { useDebouncedWindowDimensions } from "@hooks/useDebouncedWindowDimensions"

// // const INITIAL_SIZE = {
// // 	width: Dimensions.get("screen").width,
// // 	height: Dimensions.get("screen").height,
// // }

// export const useCCWindowDimensions = () => {
// 	// const [screenSize, setScreenSize] = useState(INITIAL_SIZE)
// 	// const shouldUpdateRef = useRef(true)
// 	// const screen = useWindowDimensions()
// 	// const appState = useAppState()

// 	// useEffect(() => {
// 	// 	if (appState === "active") {
// 	// 		setTimeout(() => {
// 	// 			shouldUpdateRef.current = true
// 	// 		}, 5000)
// 	// 	} else {
// 	// 		shouldUpdateRef.current = false
// 	// 	}
// 	// }, [appState])

// 	// useEffect(() => {
// 	// 	if (!shouldUpdateRef.current) return
// 	// 	if (
// 	// 		screenSize.width === screen.width &&
// 	// 		screenSize.height === screen.height
// 	// 	) {
// 	// 		return
// 	// 	}
// 	// 	setScreenSize({ width: screen.width, height: screen.height })
// 	// }, [shouldUpdateRef.current, screen.width, screen.height])
// 	const screenSize = {
// 		height: window.screen.availHeight,
// 		width: window.screen.availWidth
// 	}

// 	return screenSize
// }

import { windowExists } from "@helpers/windowExists"
import { useEffect, useState } from "react"

const INITIAL_WINDOW_SIZE = windowExists()
	? {
			width: window.innerWidth,
			height: window.innerHeight
	  }
	: { width: 0, height: 0 }

export const useCCWindowDimensions = () => {
	const [windowSize, setWindowSize] = useState(INITIAL_WINDOW_SIZE)
	useEffect(() => {
		window.addEventListener("resize", setCurrentWindowSize)
		setCurrentWindowSize()
		return () => window.removeEventListener("resize", setCurrentWindowSize)
	}, [])

	const setCurrentWindowSize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight
		})
	}

	return windowSize
}
