import { Box, Button, Center, Heading, Text } from "@chakra-ui/react"
import { CCVStack } from "@components/CCVStack"
import { useCallOnceOnMount } from "@hooks/useCallOnceOnMount"
import * as Sentry from "@sentry/react"
import { colors } from "@styles/colors"
import Image from "next/image"
import React, { ComponentProps, FC } from "react"

// type Props = {
// 	children: React.ReactNode
// }
// type State = {
// 	hasError: boolean
// }

// export class ErrorBoundary extends React.Component {
// 	public state: State = {
// 		hasError: false
// 	}

// 	constructor(props: Props) {
// 		super(props)
// 		this.state = { hasError: false }
// 	}

// 	static getDerivedStateFromError(error: any) {
// 		// Update state so the next render will show the fallback UI.
// 		return { hasError: true }
// 	}

// 	componentDidCatch(error: any, errorInfo: ErrorInfo) {
// 		// You can also log the error to an error reporting service
// 		// logErrorToMyService(error, errorInfo)
// 	}

// 	render() {
// 		if (this.state.hasError) {
// 			// You can render any custom fallback UI
// 			return (
// 				<Center w="100vw" h="100vh">
// 					<CCVStack spacing={10} alignItems={"center"}>
// 						<Heading color={colors.text.black[400]}>問題が発生しました</Heading>
// 						<Button onClick={() => window.location.reload()}>
// 							<Text>リロードする</Text>
// 						</Button>
// 					</CCVStack>
// 				</Center>
// 			)
// 		}

// 		return (this.props as Props).children
// 	}
// }

type Fallback = ComponentProps<typeof Sentry.ErrorBoundary>["fallback"]

type Props = {
	children: React.ReactNode
}

export const ErrorBoundary: FC<Props> = ({ children }) => {
	return (
		<Sentry.ErrorBoundary fallback={FallbackComponent}>
			{children}
		</Sentry.ErrorBoundary>
	)
}

const FallbackComponent: Fallback = ({ error, componentStack, resetError }) => {
	return (
		<Center w="100vw" h="100vh">
			<Logger
				error={error}
				componentStack={componentStack}
				resetError={resetError}
			/>
			<CCVStack spacing={10} alignItems={"center"}>
				<Box textAlign={"center"}>
					<Heading color={colors.text.black[400]} mb={3}>
						問題が発生しました
					</Heading>
					<Image
						src="/images/kurasuke/Kurasuke_apologize.png"
						alt="くらすけ"
						objectFit="contain"
						width={200}
						height={200}
					/>
				</Box>
				<Button onClick={() => window.location.reload()} w="200px">
					<Text>リロードする</Text>
				</Button>
				<Button
					variant="outline"
					onClick={() => {
						window.history.back()
					}}
					w="200px"
				>
					<Text>前の画面に戻る</Text>
				</Button>
			</CCVStack>
		</Center>
	)
}

type LoggerProps = {
	error: Error
	componentStack: string | null
	resetError: () => void
}

const Logger: FC<LoggerProps> = ({ error, componentStack, resetError }) => {
	useCallOnceOnMount(() => {
		Sentry.captureException(error)
		Sentry.captureException(componentStack)
	})
	return null
}
