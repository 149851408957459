import { CONFIG } from "@constants/const"
import amplitude from "amplitude-js"

import { CourseSimpleRead } from "@type/models/Course"
import { UserRoleTypeEnum } from "@type/models/User"

export const init = (amplitudeId: string): void => {
	amplitude.getInstance().init(amplitudeId)
	console.log("amplitude tracking start")
}
const optOut = (): void => {
	getInstance()?.setOptOut(true)
}
const optIn = (): void => {
	getInstance()?.setOptOut(false)
}

const getInstance = () =>
	CONFIG.AMPLITUDE_SDK_ID ? amplitude.getInstance() : undefined

type Event = string
type Properties = { [key in string]: string | number | (string | number)[] }

const logEvent = (event: Event, properties?: Properties): void => {
	getInstance()?.logEvent(event, { ...properties, platform: "web" })
}

const logActionEvent = (
	action: ConcreteAction,
	properties?: Properties
): void => {
	logEvent("Action-" + action, { ...properties })
}

const logErrorFromActionEvent = (
	action: ConcreteAction,
	properties?: Properties
): void => {
	logEvent("Error-" + action, { ...properties })
}

const logScreen = (screenName: string, properties?: Properties): void => {
	logEvent("Screen-" + screenName, {
		...properties
	})
}

type UserProperties = {
	id: string
	roleType: UserRoleTypeEnum
	roleId: string
	currentCourse?: CourseSimpleRead
	classroomsPropertiesString?: string
}

const setUserPropertiesToLogger = ({
	id,
	...restProperties
}: UserProperties): void => {
	getInstance()?.setUserId(id)
	getInstance()?.setUserProperties(restProperties)
}

const clearUserPropertiesFromLogger = () => getInstance()?.setUserId(null)

export const AnalyticsLog = {
	init,
	optIn,
	optOut,
	logEvent,
	logActionEvent,
	logErrorFromActionEvent,
	logScreen,
	setUserPropertiesToLogger,
	clearUserPropertiesFromLogger
}

type ErrorFromAction = `Error-${ConcreteAction}`
type AbstractAction = `Action-${ConcreteAction}`
type ConcreteAction =
	| "sendSchoolJoinCode"
	| "sendTeacherProfile"
	| "sendClassroomJoinCode"
	| "sendStudentProfile"
	| "sendPatronProfile"
	| "sendPatronCode"
	| "login"
	| "loginByOnetimeCode"
	| "logout"
	| "post"
	| "comment"
	| "postUpdate"
	| "commentUpdate"
	| "reactionToComment"
	| "reactionToPost"
	| "drawVideo"
	| "drawImage"
	| "compareMedia"
	| "setImageFromWebViewVideoDrawCanvasToCommentForm"
	| "setImageFromWebViewImageDrawCanvasToCommentForm"
	| "openVideoMenu"
	| "downloadVideoCanvasImage"
	| "downloadImageCanvasImage"
	| "downloadVideo"
	| "downloadImage"
	| "searchPost"
	| "searchComment"
	| "openCanvasEditPage"
