import React, { FC, createContext, useState } from "react"

type LayoutContextType = {
	shouldShowFooter: boolean
	setShouldShowFooter: React.Dispatch<React.SetStateAction<boolean>>
	shouldShowHeader: boolean
	setShouldShowHeader: React.Dispatch<React.SetStateAction<boolean>>
}

const initialValues = {
	shouldShowFooter: true,
	setShouldShowFooter: () => {
		console.log("initial")
	},
	shouldShowHeader: true,
	setShouldShowHeader: () => {
		console.log("initial")
	}
}

export const LayoutContext = createContext<LayoutContextType>(initialValues)

type Props = {
	children: React.ReactNode
}
export const LayoutContextProvider: FC<Props> = ({ children }) => {
	const [shouldShowFooter, setShouldShowFooter] = useState(true)
	const [shouldShowHeader, setShouldShowHeader] = useState(true)
	return (
		<LayoutContext.Provider
			value={{
				shouldShowFooter,
				setShouldShowFooter,
				shouldShowHeader,
				setShouldShowHeader
			}}
		>
			{children}
		</LayoutContext.Provider>
	)
}
