import {
	extractQueryStringValueFromCurrentUrl,
	queryString2Object
} from "@helpers/extractQueryStringValue"
import { AnalyticsLog } from "@utils/analytics/amplitude"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { CONFIG } from "@constants/const"

export const usePageViewTracking = () => {
	const router = useRouter()

	useEffect(() => {
		if (!CONFIG.AMPLITUDE_SDK_ID) {
			return
		}
		return
		const handleRouteChange = (path: string) => {
			const onlyPath = path.split("?")[0]
			const params = queryString2Object(path)
			AnalyticsLog.logScreen(onlyPath, params)
		}
		router.events.on("routeChangeComplete", handleRouteChange)
		return () => {
			router.events.off("routeChangeComplete", handleRouteChange)
		}
	}, [router.events])

	return {}
}
