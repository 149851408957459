import { useUser } from "@hooks/useUser"
import { getNendoStartDay } from "@utils/nendo"
import useSWR from "swr"
const getKey = () => `/api/v1/students/me/last_classroom_join_date`

export const useShouldJoinNewClassroom = () => {
	const user = useUser()
	const {
		data,
		mutate,
		isValidating: isLoading
	} = useSWR<{ lastClassroomJoinDate: string }>(
		user?.isStudent ? getKey() : null
	)

	const joinDate = data?.lastClassroomJoinDate
	const shouldJoinNewClassroom =
		!isLoading &&
		user?.isStudent &&
		joinDate &&
		new Date(joinDate) < getNendoStartDay(new Date())

	return {
		data,
		refetch: mutate,
		shouldJoinNewClassroom,
		isLoading
	}
}
